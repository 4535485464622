import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '../components/common/Button';
import Card from '../components/common/Card';
import ContentContainer from '../components/common/ContentContainer';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ContentfulContent from './../components/common/ContentfulContent';
import { extractContent } from './../util';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

type Service = {
  id: string;
  image: {
    fluid: {
      src: string;
    };
  };
  name: string;
  description: {
    description: string;
  };
};

const Services: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      servicesImage: file(relativePath: { eq: "images/about.png" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      mainImage: file(relativePath: { eq: "images/homegrown.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 300) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      allContentfulService(
        filter: { node_locale: { eq: "en-US" } }
        sort: { order: ASC, fields: pagePosition }
      ) {
        edges {
          node {
            image {
              fluid {
                src
              }
            }
            id
            name
            description {
              description
            }
            brochureLink
          }
        }
      }
    }
  `);

  const services = extractContent(data.allContentfulService);

  return (
    <Layout>
      <SEO title="Services" />
      <ContentContainer>
        <div className="self-center text-center">
          <h1 className="text-3xl font-bold text-primary">
            Our Professional Development Services
          </h1>
        </div>
        <div className="mt-4">
          <div className="flex flex-col items-center justify-center text-center sm:flex-row">
            <div className="w-full mb-6 sm:w-2/3">
              <p className="mt-4 mr-4 text-xl text-gray-600">
                Mossflower Reading and Writing Project conducts professional
                development for reading, writing, and phonics instruction for
                grades K-8. We work directly with teachers, literacy coaches,
                school principals, and district leaders, aiming to tailor our
                work to the needs of each individual school and their students.
                <br />
                <br />
                <Link to="/contact">
                  <span className="text-blue-500 cursor-pointer hover:underline">
                    Contact Us To Learn More and Apply for Services
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <section className="mt-4">
          {services.map((s: Service, i: number) => (
            <section className="mb-6" key={i}>
              <Card>
                <h3 className="text-xl font-bold text-primary">{s.name}</h3>
                <div className="flex flex-col my-10 sm:flex-row">
                  <div className="w-full sm:w-1/4">
                    <img
                      className="w-full rounded-lg"
                      src={s.image?.fluid?.src}
                    />
                    {s.name !== 'Asynchronous Courses' ? (
                      <div className="flex justify-center gap-4 mt-4 mb-2 text-sm text-center sm:mb-0">
                        {[
                          {
                            name:
                              'Annual Professional Development (our most popular option)',
                            link: '/annual-professional-development'
                          },
                          {
                            name: 'Units of Study Day',
                            link: '/units-of-study-day'
                          },
                          {
                            name: 'Week-Long Homegrown Institute',
                            link: '/homegrown-institute'
                          },
                          {
                            name: 'Advanced Special Topic Day',
                            link: '/special-topic'
                          }
                        ].map(
                          item =>
                            s.name === item.name && (
                              <Link to={item.link} key={item.name}>
                                <Button text="Learn More" />
                              </Link>
                            )
                        )}
                        {/* <Link to="/contact">
                          <Button text="Apply for Services" />
                        </Link>
                        {s.brochureLink ? (
                          <Link to={s.brochureLink} target="_blank">
                            <Button text="Download Brochure" />
                          </Link>
                        ) : null} */}
                      </div>
                    ) : null}
                    {s.name === 'Asynchronous Courses' ? (
                      <div className="mt-4 mb-2 text-center sm:mb-0">
                        <Link to="/asynchronous-courses">
                          <Button text="View Courses" />
                        </Link>
                      </div>
                    ) : null}
                  </div>
                  <div className="w-full p-2 sm:w-3/4 sm:p-6 sm:pt-0 typography content-body">
                    <ContentfulContent content={s.description.description} />
                    {s.name ===
                    'Professional Development with Schools, including Introductory Packages' ? (
                      <div className="flex mt-9">
                        <div className="mr-2">
                          <Link
                            to="https://drive.google.com/file/d/1frlZ8Up-RcxHQJPIDpo0-LE6SB51iRmC/view"
                            target="_blank"
                          >
                            <Button text="Download Introductory Brochure" />
                          </Link>
                        </div>
                        <div className="mr-2">
                          <Link
                            to="https://drive.google.com/file/d/1NQcFCXUBpk_dmFuBdvEKpll_C6rzvOz-/view"
                            target="_blank"
                          >
                            <Button text="Download Full Partner School Brochure" />
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Card>
            </section>
          ))}
        </section>
      </ContentContainer>
    </Layout>
  );
};

export default Services;
